.sk-wave {
  position: relative;
  top: 50%;
  margin: auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
  .sk-rect {
    height: 100%;
    width: 6px;
    display: inline-block;
    -webkit-animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
    animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
  }
  .sk-rect1 {
    -webkit-animation-delay: -1.2s;
    animation-delay: -1.2s;
  }
  .sk-rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }
  .sk-rect3 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  .sk-rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
  .sk-rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
}

@-webkit-keyframes sk-waveStretchDelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
  }

  20% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}


@keyframes sk-waveStretchDelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
  }

  20% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
