.sk-wandering-cubes {
  top: 50%;
  margin: auto;
  width: 40px;
  height: 40px;
  position: relative;
  .sk-cube {
    width: 10px;
    height: 10px;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-wanderingCube 1.8s ease-in-out -1.8s infinite both;
    animation: sk-wanderingCube 1.8s ease-in-out -1.8s infinite both;
  }
  .sk-cube2 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
}

@-webkit-keyframes sk-wanderingCube {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  25% {
    -webkit-transform: translateX(30px) rotate(-90deg) scale(0.5);
    transform: translateX(30px) rotate(-90deg) scale(0.5);
  }

  50% {
    /* Hack to make FF rotate in the right direction */
    -webkit-transform: translateX(30px) translateY(30px) rotate(-179deg);
    transform: translateX(30px) translateY(30px) rotate(-179deg);
  }

  50.1% {
    -webkit-transform: translateX(30px) translateY(30px) rotate(-180deg);
    transform: translateX(30px) translateY(30px) rotate(-180deg);
  }

  75% {
    -webkit-transform: translateX(0) translateY(30px) rotate(-270deg) scale(0.5);
    transform: translateX(0) translateY(30px) rotate(-270deg) scale(0.5);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}


@keyframes sk-wanderingCube {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  25% {
    -webkit-transform: translateX(30px) rotate(-90deg) scale(0.5);
    transform: translateX(30px) rotate(-90deg) scale(0.5);
  }

  50% {
    /* Hack to make FF rotate in the right direction */
    -webkit-transform: translateX(30px) translateY(30px) rotate(-179deg);
    transform: translateX(30px) translateY(30px) rotate(-179deg);
  }

  50.1% {
    -webkit-transform: translateX(30px) translateY(30px) rotate(-180deg);
    transform: translateX(30px) translateY(30px) rotate(-180deg);
  }

  75% {
    -webkit-transform: translateX(0) translateY(30px) rotate(-270deg) scale(0.5);
    transform: translateX(0) translateY(30px) rotate(-270deg) scale(0.5);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
