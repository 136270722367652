.card-header-right {
    z-index: 999;
}

.card.full-card {
  position: fixed;
  top: 60px;
  z-index: 99999;
  box-shadow: none;
  left: 0;
  border-radius: 0;
  border: 1px solid #ddd;
  width: 100%;
  height: calc(100vh - 56px);
  margin: 30px 100px 50px 0px;
}
